import React, { useCallback, useEffect, useState } from 'react';
import './SelectStudentProfile.scss';
import {useAuth, PREV_AUTH_LOCAL_STORAGE_KEY, getAuthPromise} from '../../app/modules/auth'
import { getAllTypeForm } from '../../network/api';
import moment from 'moment';
import { debounce } from 'lodash';
import { NewAndIncAppFormRenderer } from './NewAndIncAppFormRenderer';

interface StudentTypeProps {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
}

interface TypeformData {
    id: number;
    form_id: string;
    response_type: string;
    name: string;
    overview: string;
  }

const FormsPage: React.FC<StudentTypeProps> = ({ formData, setFormData }) => {

    const [getTypeForm, setTypeform] = useState<TypeformData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasAdmin, setHasAdmin] = useState<any>(null)

    useEffect(() => {
        const getCurrentAuth = async () => {
        const PrevAuth = await getAuthPromise(PREV_AUTH_LOCAL_STORAGE_KEY)
        if (PrevAuth) {
            setHasAdmin(true)
        } else {
            setHasAdmin(false)
        }
        }
        getCurrentAuth()
    }, []);

    const fetchData = useCallback(
        debounce(async () => {
          try {
            setIsLoading(true);
    
            // Fetch typeform
            const [typeformRes] = await Promise.all([
              getAllTypeForm(),
            ]);
    
            const { data: typeformData } = typeformRes;
            const categoryForms = typeformData.filter((form: { category: string }) => form.category === "application");
            
            const studentIds = Array.isArray(formData.student_contact_id) ? formData.student_contact_id : [formData.student_contact_id];
            if (studentIds.length === 0) {
              console.error('No student IDs found.');
              return;
            }
    
            setTypeform(categoryForms);
    
          } catch (error) {
            console.error('Error fetching data:', error);
          } finally {
            setIsLoading(false);
          }
        }, 300),
        [formData]
      );
    
      useEffect(() => {
        fetchData();
        return () => {
          fetchData.cancel()
        };
      }, [fetchData]);

    const campus = formData?.decodedProgram
    const session = `${formData?.selectedSession?.session_name} (${moment.utc(formData?.selectedSession?.start_date).format('MM/DD/YYYY')} - ${moment.utc(formData?.selectedSession?.end_date).format('MM/DD/YYYY')}) ${formData?.selectedProgram?.name}`

    return (
			<>
				<h2 className='mx-3' style={{ margin: '45px 0 30px' }}>{campus}: {session}</h2>
				<div className='flex-grow-1 mx-3' style={{ alignSelf: 'flex-start' }}>
					{isLoading ? (
						<div className="spinner-border" role="status">
								<span className="sr-only">Loading...</span>
						</div>
					) : getTypeForm.length === 0 ? (
							<div style={{ textAlign: 'center', background: '#f8d7da', color: '#721c24', padding: '10px'}}>
									Forms missing, Please contact the administrator.
							</div>
					) : (
						<table className='table table-bordered'>
							<thead>
									<tr>
											<th>Section</th>
											<th>Task Name</th>
											<th>Overview</th>
											<th>Status</th>
											<th style={{ width: 70 }}>Action</th>
									</tr>
							</thead>
							<tbody style={{ border: '1px solid #dee2e6' }}>
                <NewAndIncAppFormRenderer
                  item={formData}
                  handleShowModal={() => {}}
                  handleShowModalConfirmation={() => {}}
                  handleShowModalConfirmationApproveDeny={() => {}}
                  hasAdmin={hasAdmin}
                  formsPage={true}
                />
							</tbody>
						</table>
					)}
				</div>
			</>
    );
};

export default FormsPage;
